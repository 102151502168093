import React, { useEffect, useState } from "react";
import BannerImg from "./../assets/img/subscription-6cover.jpg";
import Logo2 from "../assets/img/nobgb.jpg";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { listBannerImages } from "../functions/CMS/banner";
import Logo from "./../assets/img/logo.png";

const SlideSection = () => {
  const [bannerData, setBannnerData] = useState([]);
  const loadBannerData = () => {
    listBannerImages()
      .then((res) => {
        console.log("res in banner", res.data);
        setBannnerData(res.data);
      })
      .catch((err) => {
        console.log("error in list banner");
      });
  };
  useEffect(() => {
    loadBannerData();
  }, []);
  return (
    <React.Fragment>

      <Carousel>

        {bannerData.sort((a, b) => a.Title.localeCompare(b.Title)).map((b) => {
          console.log("bb", b.IsActive, b.Type);
          if (b.IsActive === true) {
            return (
              <Carousel.Item key={b._id} interval={5000000} style={{
                position: 'relative',
                marginTop: window.innerWidth <= 768 ? 0 : 40,
              }}>
                <div className="slider-divv" style={{ position: 'relative' }}>
                  {b.Type === 'video' ? (
                    <video
                      autoPlay
                      loop
                      muted
                      preload="none"
                      className="bannerImg"
                      style={{ marginTop: 0 }}
                    >
                      <source
                        src={`${process.env.REACT_APP_API_URL_COFFEE}/${b.bannerImage}`}
                        type="video/mp4"
                      />
                    </video>

                  ) : (
                    <img
                      src={`${process.env.REACT_APP_API_URL_COFFEE}/${b.bannerImage}`}
                      alt=""
                      className="bannerImg"
                      style={{ marginTop: 0 }}
                    />
                  )}
                  {/* Top-right image */}
                  <img
                    src={Logo}
                    alt="Top Right Image"
                    style={{
                      position: 'absolute',
                      top: '30px',
                      right: '10px',
                      width: '130px',
                      height: 'auto',
                      zIndex: 2,
                      marginTop: window.innerWidth <= 768 ? '0.5vh' : '5vh',
                      marginRight: window.innerWidth <= 768 ? '10px' : '50px',
                    }}
                  />
                </div>
                <div
                  className="descriptionDiv"
                  style={{
                    fontFamily: 'Arial, Helvetica, sans-serif',
                    color: 'white',
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: b.Description,
                    }}
                  ></p>
                </div>
              </Carousel.Item>

            );
          } else {
            return null;
          }
        })}
        {/* </Carousel.Item> */}
        {/* <Carousel.Item> 
      <div>
       
       <video
         playsInline
         autoPlay
         loop
         muted
         preload="none" 
         className="bannerImg"
         style={{ marginTop: 0 }}
       >
         <source
           src="https://ceremonycoffee.com/wp-content/uploads/ceremony-coffee-roasters-locations-hero-1_1_1.mp4"
           type="video/mp4"
         />
       </video>
     </div>
     <div className="descriptionDiv"  style={{fontFamily: "Arial, Helvetica, sans-serif"}}>
       <h6 className="descriptionTitle">
      
         Elevate Your Coffee Routine
       </h6>
       <h4 className="descriptionSubTitle">Subscribe and Save 10%</h4>
       <p className="description">
         No commitments, just repeat coffee shipments at your convenience.
       </p>
       <Link to="#">GET STARTED</Link>
     </div>
      </Carousel.Item> */}
      </Carousel>
    </React.Fragment>
  );
};

export default SlideSection;
